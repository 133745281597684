<template>
  <div>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :row-clicked="navigateToItem"
      :filter-class="new ServiceOrder()"
    >
      <template #cell(order_no)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'order_id', '&mdash;') }}
      </template>
      <template #cell(state)="{ data: { item } }">
        <status-filter-table :item="item" />
      </template>
      <template #cell(order_date)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'order_date', '&mdash;') }}
      </template>
      <template #cell(to)="{ data: { item } }">
        <div
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <feather-icon
            :icon="checkIconType(getValueFromGivenObjectByKey(item, 'to.details.status', '&mdash;'))"
            size="18"
          />
          <!--   LTickIcon       -->
          <span>
            {{ getValueFromGivenObjectByKey(item, 'to.cust_name_dba', '&mdash;') }}
          </span>
        </div>
      </template>

      <template #cell(ship_by)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'shipping', '&mdash;') }}
      </template>
      <template #cell(return_by)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'return', '&mdash;') }}
      </template>
      <template #table-top-right-side-extras-prev="">
        <status-filter-color-key />
      </template>
    </l-table>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'

// Components
import LTable from '@/views/components/LTable/LTable.vue'
// eslint-disable-next-line import/no-cycle
import { assetStates } from '@/enum/asset-statuses'
import ServiceOrder from '@/views/components/filters/fields/orders/service/Service'
import warehouseConfig from '@/views/main/warehouse/main-order/config'
import config from '../config'
import StatusFilterColorKey from '../../../components/use-as-global/StatusFilterColorKey.vue'
import StatusFilterTable from '../components/list/StatusFilterTable.vue'

export default {
  name: 'List',
  components: {
    LTable,
    StatusFilterTable,
    StatusFilterColorKey,
  },
  computed: {
    ServiceOrder() {
      return ServiceOrder
    },
  },
  methods: {
    navigateToItem({
      id, state, status, service_type, inventory_item_id,
    }) {
      const orderItem = {
        order_type: this.ORDER_TYPES_KV.SM,
        fulfillment_state: state,
        fulfillment_status: status,
        id,
      }
      if ((state === 2 && status === 4) || (state >= 3)) {
        this.handleOrderStatus(this, orderItem)
        return
      }
      if (this.SERVICE_TYPE_OUT === service_type) {
        if (state === assetStates.IN.code && (status === 1 || status === 5 || status === 0)) {
          this.$router.push({ name: 'request-service-quote-view', params: { id } })
        }
        // if (state.id === 0 && (status.id === 1 || status.id === 5) && this.SERVICE_TYPE_OUT === service_type) {
        //   this.$router.push({ name: 'request-service-quote-view', params: { id } })
        // }
        if (state === assetStates.PICKED.code && status === 1) {
          this.$router.push({ name: 'received-quote', params: { id } })
        }
        if (state === assetStates.PACKED.code && (status === 1 || status === 4)) {
          this.$router.push({ name: 'invoice', params: { id } })
        }
        if (state === -1 && status === 0) {
          this.$router.push({ name: 'home-orders-request-service-quote', params: { id: inventory_item_id } })
        }
      }

      if (this.SERVICE_TYPE_IN === service_type) {
        if (state === assetStates.IN.code && status === 1) {
          this.$router.push({ name: 'request-service-quote-in-house-view', params: { id } })
        }
        if (state === assetStates.PICKED.code && status === 1) {
          this.$router.push({ name: 'received-quote-in-house', params: { id } })
        }
        if (state === assetStates.PACKED.code && status === 1) {
          this.$router.push({ name: 'received-quote-in-house', params: { id } })
        }
        if (state === -1 && status === 0) {
          this.$router.push({ name: 'home-orders-request-service-quote', params: { id: inventory_item_id } })
        }
      }
    },

    checkIconType(status) {
      if (status === 1) {
        return 'LTickIcon'
      } if (status === 2) {
        return 'LNoIcon'
      }
      return 'LWarningIcon'
    },
  },
  setup() {
    const {
      MODULE_NAME, tableColumns, SERVICE_TYPE_OUT, SERVICE_TYPE_IN,
    } = config()
    const { ORDER_TYPES_KV, handleOrderStatus } = warehouseConfig()

    return {
      MODULE_NAME,
      tableColumns,
      SERVICE_TYPE_IN,
      SERVICE_TYPE_OUT,
      getValueFromGivenObjectByKey,
      ORDER_TYPES_KV,
      handleOrderStatus,
    }
  },
}
</script>
